
            @import "@/assets/sass/vars.portal.scss";
          




































































.password-score {
    &__word {
        margin-top: 15px;
        text-align: right;
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        div {
            background-color: $gray-200;
            border: 1px solid white;
            border-radius: 4px;
            width: 32%;
            height: 10px;
        }

        &__score1 {
            &.active {
                background-color: #aa0033;
            }
        }

        &__score2 {
            &.active {
                background-color: #ffcc33;
            }
        }

        &__score3 {
            &.active {
                background-color: #2d98f3;
            }
        }

        &__score4 {
            &.active {
                background-color: #76c261;
            }
        }
    }
}
